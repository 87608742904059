import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// CSS
import './Footer.scss';

class Footer extends Component {
    render() {
        return (
            <div></div>
        );
    }
}

export default Footer;